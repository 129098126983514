<template>
    <el-dialog title="编辑视频案例" :visible.sync="confitionDialog" append-to-body custom-class='class-dialog ' width="600px">

        <div class="dialog-inside sourceDialog">
            <div class="searchdan">
                <div class="title" style="margin-right: 0px !important;">视频名称：</div>
                <el-input v-model="video_name" placeholder="请输入视频名称"></el-input>
            </div>
        </div>
        <div class="dialog-inside sourceDialog">
            <div class="searchdan">
                <div class="title" style="margin-right: 0px !important;">上传新配音：</div>
                <el-upload class="upload-demo" ref='uploadbud' :action="$api.uploadFile + '?token=' + Token" accept=".mp4,.m4a,.mp3," :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed" :on-success="onSuccessbud" :file-list="fileList">
                    <el-button size="small" type="primary" style="margin-left:-20px">点击上传</el-button>
                </el-upload>
            </div>
        </div>
        <div class="dialog-inside sourceDialog">
            <el-upload class="avatar-uploader" ref='upload' :action="$api.uploadFile + '?token=' + Token" alt="" list-type="picture-card" accept=".mp4, .mov, .MOV" :on-success="onAcchandleAvatarSuccess" :on-remove="onAcchandleRemove" :before-upload="beforeAvatarUpload" :destroy-on-close='true' :drag='true' :file-list="fileList" :on-exceed="handleExceed" :limit="1" multiple>
                <!-- multiple -->
                <img v-if="acc_img" :src="acc_img" class="avatar">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip" slot="tip">扩展名为：.mp4、.MOV</div>
            </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog" :disabled='delenteAnniu'>取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog" :disabled='delenteAnniu'>提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            Token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            confitionDialog: false, //弹窗
            delenteAnniu: false, //按钮禁用
            fileList: [], //上传列表
            formListMp4: [],
            mixer_id: "", //配音师id
            file_id: "", //视频id
            cover_img: "", //文件图片
            video_name: "", //文件名称
            acc_img: "", //图片
            id: "",
            frequency: "",
        };
    },

    mounted() {},

    methods: {
        //配音上传成功
        onSuccessbud(res, file, fileList) {
            this.frequency = res.data.file_id;
            // console.log(res.data);
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        // handleExceed(files, fileList) {
        //     this.$message.warning(
        //         `当前限制选择 1 个文件，本次选择了 ${
        //             files.length
        //         } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        //     );
        // },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        clear() {
            this.fileList = [];
            this.formListMp4 = [];
            this.$refs.upload.clearFiles();
        },
        //编辑触发
        onconfitionDialog(data) {
            this.confitionDialog = true; //弹窗
            this.video_name = data.video_name;
            this.acc_img = data.cover_img;
            this.id = data.id;
            this.cover_img = data.cover_img;
        },
        //附件上传
        onAcchandleAvatarSuccess(res, file, fileList) {
            this.acc_img = "";
            if (res.code == 200) {
                this.fileList = fileList;
                this.oncutVideo(res.data, file);
            } else {
                this.fileList.splice(fileList.length - 1, 1);
                this.$message.error(res.message);
            }
        },
        //附件删除
        onAcchandleRemove(file, fileList) {
            this.formListMp4 = this.onImgUrlList(fileList);
            // console.log(this.formListMp4,'附件删除')
        },
        //文件上传控制大小
        beforeAvatarUpload(file, fileList) {
            const isLt2M = file.size / 1024 / 1024 < 300;
            if (!isLt2M) {
                this.$message.error("上传文件大小不能超过 300MB!");
            }
            return isLt2M;
        },
        //文件上传超过数量提示
        handleExceed(files, fileList) {
            this.$message.warning(`只能上传一个视频`);
        },
        //图片列表循环获取id
        onImgUrlList(List) {
            var ImgUrlList = [];
            List.forEach((v, k) => {
                if (v.response) {
                    var imgData = {
                        file_id: v.response.data.file_id,
                        first_img_id: v.response.data.first_img_id,
                    };
                    ImgUrlList.push(imgData);
                } else {
                    var imgData = {
                        file_id: v.file_id,
                        first_img_id: v.first_img_id,
                    };
                    ImgUrlList.push(imgData);
                }
            });
            return ImgUrlList;
        },
        // 视频切帧
        oncutVideo(data, file) {
            var fileList = this.fileList;
            this.file_id = data.file_id;

            var param = {
                // deleteJiaZai: true,
                file_url: data.file_url,
                file_id: data.file_id,
            };
            this.$service.post(this.$api.videoToImg, param, (res) => {
                if (res.code == "200") {
                    this.cover_img = res.data.cover_img;
                    this.file_id = res.data.file_id;
                    file.response.data.first_img_id = res.data.file_id;
                    file.url = res.data.cover_img;
                    fileList.forEach((v, k) => {
                        if (file.response.data.id == v.response.data.id) {
                            v = file;
                        }
                    });
                    this.fileList = fileList;
                    this.formListMp4 = this.onImgUrlList(this.fileList);
                    // this.video_first_file_id = res.data.id
                }
            });
        },
        //弹窗打开
        onDialog(mixer_id) {
            this.confitionDialog = true;
            this.mixer_id = mixer_id;
        },
        //取消
        onCancel() {
            this.fileList = [];
            this.formListMp4 = [];
            this.confitionDialog = false;
            this.acc_img = "";
            this.$refs.uploadbud.clearFiles();
        },
        //提交
        onSubmit() {
            // id  video_name  file_id
            var param = {
                id: this.id,
                cover_img: this.cover_img,
                video_name: this.video_name,
                file_id: this.file_id,
                frequency: this.frequency,
            };
            this.$service.post(this.$api.editMixersampleVideo, param, (res) => {
                if (res.code == "200") {
                    this.acc_img = "";
                    this.$refs.upload.clearFiles();
                    this.$refs.uploadbud.clearFiles();
                    this.$message({ type: "success", message: "编辑成功!" });
                    this.$parent.onDataList();
                }
            });
            // var formListMp4 = this.formListMp4;
            // console.log(this.formListMp4);
            // this.$emit("onSourceList", this.formListMp4);
            // this.$parent.onSourceList(this.formListMp4)
            this.confitionDialog = false;
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.avatar {
    width: 146px;
    height: 146px;
    margin-right: 20px;
}
</style>
<style lang="scss">
.sourceDialog {
    .searchdan {
        display: flex;
    }
    .title {
        width: 120px;
        line-height: 33px;
        display: inline-block;
    }
    .el-upload-dragger {
        width: 100%;
        height: 100%;
        border: none;
    }
    .el-input__inner {
        width: 300px;
    }
}
</style>