<template>
    <div class="case">

        <!-- <searchSub ref="Search" @onAllSearch="onAllSearch" @onificaList="onificaList"></searchSub> -->
        <div class="group-table padd20" v-if="mixer_id">
            <div class="duoshao">
                <div class="title" style="display:flex;">
                    案例列表
                    <el-button type="primary" icon="el-icon-upload" @click="onVideoDialog()">添加案例</el-button>
                </div>
                <div class="lvtotal">
                    总条数：{{lvtotal}} 条
                </div>
            </div>
            <div class="TableList">
                <div class="dan" v-for="(item,key) in TableList" :key="key" style="width:290px;margin-right:20px; position: relative;">
                    <div class="videoimg" @click="onvideo(item.file,item,key)">
                        <i class="iconfont icon-bofang"></i>
                        <img class="img" :src="item.cover_img + '?token=' + Token" alt="">
                    </div>
                    <div class="datetime">
                        <span style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1 !important;  overflow: hidden; margin:10px 20px">{{item.video_name}}</span>
                    </div>
                    <div class="videoTest">

                        <!-- <div class="videoname" @click="onVideoDialog(item)">替换素材</div> -->
                        <div class="videolabel" style="margin-bottom:10px">
                            <div class="toview" @click="onDelete(item)" style="margin-left:10px;cursor: pointer;">
                                <i class="el-icon-delete" style="color:#f00"></i>
                            </div>
                            <div class="toview" @click="onReplace(item)" style="margin-left:10px;cursor: pointer;">
                                <i class="el-icon-setting" style="color:#0075f7"></i>
                                <span style="margin-left:5px;color:#0075f7">编辑素材</span>
                            </div>
                            <div class="toview" @click="onDownload(item.file_id)" style="margin-left:15px;cursor: pointer;">
                                <a :href="item.file.file_url" target="_blank" style="margin-right:10px"><i class="el-icon-download"></i>下载视频</a>
                            </div>
                            <div class="toview" @click="onFrequency(item)" style="cursor: pointer;">
                                <i class="el-icon-headset" style="margin-left:5px;color:#0075f7"></i><span style="margin-left:5px;color:#0075f7">播放音频</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        <!-- <Left ref="Left" style="display:none"></Left>
        <VideoDialog ref="VideoDialog" :myvideo='myvideo'></VideoDialog>
        <Replace ref="Replace"></Replace>
        <technique ref="technique"></technique>-->
        <VideoSourceDialog ref="VideoSourceDialog"></VideoSourceDialog>
        <LookVideos ref="LookVideo" :VideoChildList="TableList"></LookVideos>
        <Redact ref="Redact"></Redact>
        <!-- 弹窗 -->
        <el-dialog title="音频播放" :visible.sync="dialogFormVisible" width="400px" @close='closeDialog' class="dialog">
            <audio controls ref="audio" :src="audio" loop="loop" class="audio">

            </audio>
        </el-dialog>
    </div>
</template>

<script>
import VideoSourceDialog from "./videoSourceDialog.vue";
// import Left from "../Allpubic/menuBar";
import Redact from "./redact.vue";
// import SearchSub from "../commonSearch/searchSub.vue";
// import Storting from "../commonSearch/storting.vue";
// import VideoDialog from "./videoDialog.vue";
// import Replace from "./Replace.vue";
// import technique from "./technique.vue";
import LookVideos from "./lookVideos.vue";
export default {
    data() {
        return {
            paramData: {}, //表格参数
            myvideo: this.$route.path == "/myvideo" ? true : false, //判断是在列表页还是在我的里
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            TableList: [], // 视频列表

            video_title: "", //视频标题
            video_type: "", //视频分类
            pickerTime: [], //日期参数

            //排序
            stortingfield: "", //排序字段
            order_type: "", //排序类型
            tableData: [],
            // stortingList:[
            //     {
            //         name:'添加时间',
            //         field:'id',
            //     },{
            //         name:'点击次数',
            //         field:'click_num',
            //     },
            // ],
            // stortingfield:'',//排序字段
            // order_type:'',//排序类型
            //
            mixer_id: "", //订单id
            //
            postIdList: [],
            videoKey: 0,
            message: "",
            dialogFormVisible: false,
            audio: "",
        };
    },

    components: {
        // Left,
        // VideoDialog,
        // Storting,
        // SearchSub,
        // Replace,
        // seed,
        // technique,
        LookVideos,
        VideoSourceDialog,
        Redact,
    },

    mounted() {
        this.mixer_id = this.$route.query.id;
        if (this.mixer_id != undefined) {
            this.onDataList();
        }
    },

    methods: {
        // 播放录音
        onFrequency(data) {
            this.audio = data.frequency.file_url;
            this.dialogFormVisible = true;
        },
        closeDialog() {
            this.audio = "";
            this.dialogFormVisible = false;
        },
        // 数据处理
        dataProcessing(val) {
            var code = this.code;
            if (val.length == 0) {
                this.$message({
                    message: "暂无视频可以下载!",
                    type: "warning",
                });
            } else {
                val.forEach((v, k) => {
                    this.message +=
                        "\n" +
                        "http://192.168.111.24" +
                        v.order_video_file.url_link;
                });
            }
        },
        //一键复制
        doCopy: function (val) {
            this.message = "";
            var that = this;
            this.dataProcessing(val);

            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },

        //下载视频
        onDownload(file_id) {
            // var param = {
            //     id: file_id,
            //     click_type: "shooting",
            // };
            // this.$service.post(this.$api.clickCount, param, (res) => {
            //     if (res.code == "200") {
            //         // this.RecordList = res.data;
            //     }
            // });
        },
        onRemove() {
            var param = {
                order_id: this.order_id,
                ids: this.postIdList,
            };
            if (param.ids == "") {
                this.$message({ message: "请勾选删除项", type: "warning" });
                return;
            }
            this.$confirm("此操作将永久删除文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$service.post(
                        this.$api.aKeyDelFilmMaterial,
                        param,
                        (res) => {
                            if (res.code == "200") {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.onDataList();
                            }
                        }
                    );
                })
                .catch(() => {
                    this.postIdList = [];
                    this.$message({ type: "info", message: "已取消删除" });
                });
        },
        //查看视频
        //
        onvideo(video, data, key) {
            // var param = {
            //     id: data.id,
            // };
            // this.$service.post(
            //     this.$api.addInternalOrderVideoClickCount,
            //     param,
            //     (res) => {
            //         if (res.code == "200") {
            //             this.onDataList();
            //         }
            //     }
            // );
            this.$refs.LookVideo.onDialog(key);
            // this.$refs.seed.confitionDialog = true;
            // this.$refs.seed.getvideo(video);
            // console.log(video)
            // window.open("http://192.168.111.24/" + video);
        },
        ontechnique() {
            this.$refs.technique.techniqueDialog = true;
            this.$refs.technique.getOrderShouList(this.order_id);
        },
        //点击排序
        // onStorting(item){
        //     if(this.stortingfield == item.field){
        //         this.order_type == '' ? this.order_type = 'asc' : this.order_type == 'asc' ? this.order_type = 'desc' : (this.order_type = '',this.stortingfield = '')
        //     }else{
        //         this.order_type = 'asc'
        //         this.stortingfield = item.field
        //     }
        //     this.onDataList()
        // },
        // 排序接收
        onStorting(type) {
            (this.stortingfield = type.stortingfield), //排序字段
                (this.order_type = type.order_type); //排序类型
            this.onDataList();
        },
        //视频删除点击触发
        onDelete(item) {
            this.$confirm("此操作将永久删除文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.ondelVideo(item);
                })
                .catch(() => {
                    this.$message({ type: "info", message: "已取消删除" });
                });
        },
        // 替换
        onReplace(row) {
            this.$refs.Redact.onconfitionDialog(row);
        },
        //批量化删除视频接口执行
        ondelVideo(item) {
            var param = {
                id: item.id,
            };
            this.$service.post(this.$api.delMixersampleVideo, param, (res) => {
                if (res.code == "200") {
                    this.$message({ type: "success", message: "删除成功!" });
                    this.onDataList();
                }
            });
        },
        //每页多少条触发条件
        handleSizeChange(val) {
            this.per_page = val;
            this.onDataList();
        },
        //接收搜索参数
        onAllSearch(data) {
            (this.video_title = data.video_title), //视频标题
                (this.video_type = data.video_type), //视频分类
                (this.pickerTime = data.pickerTime), //日期参数
                this.onSearch();
        },

        //点击商品分类
        onificaList(item) {
            item == "全部"
                ? (this.video_type = "")
                : (this.video_type = item.id);
            this.paramData.video_type = this.video_type;
            this.onDataList();
        },
        //商品搜索
        onSearch() {
            this.paramData = {
                video_title: this.video_title, //视频标题
                video_type: this.video_type, //分类搜索
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
            };
            this.paramPage = 1;
            this.onDataList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.onDataList();
        },
        //数据列表
        onDataList() {
            this.TableList = [];
            var param = this.paramData;
            param.mixer_id = this.mixer_id;
            // param.current_page  = this.paramPage;
            this.$service.post(
                this.$api.getMixersampleVideoList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.TableList = res.data;
                        this.lvtotal = res.data.length;
                    }
                }
            );
        },
        //点击视频弹窗
        onVideoDialog(item) {
            this.$refs.VideoSourceDialog.onDialog(this.mixer_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.case {
    .TableList {
        display: flex;
        flex-wrap: wrap;
        .dan {
            width: 300px;
        }
        .videolabel {
            display: flex;
        }
        .videoimg {
            height: 180px;
            overflow: hidden;
            position: relative;
            background: #000;
            text-align: center;
        }
        .img {
            height: 100%;
            width: auto;
            transition: 0.5s;
            opacity: 0.8;
        }
    }
    .duoshao {
        line-height: 33px;
    }
    .audio {
        height: 100px;
        padding: 40px 0px;
    }
}
</style>
<style lang="scss">
.el-dialog__header {
    background: #0075f7;
}
.el-dialog__title {
    color: #fff;
}
// .el-table:before {
//     display: none;
// }
</style>