<template>
    <el-dialog :visible.sync="dialogVisible" append-to-body width="450px" custom-class='lookVideo' top="40px" @close="onCancel" :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="lookVideo-nei">
            <div class="previousPage sizePage" @click="onpreviousPage()"><i class="el-icon-arrow-left"></i></div>
            <div class="shipin">
                <video ref="videoLook" v-if="video_url_link" controls width="400px">
                    <source :src="video_url_link + '?token=' + Token" type="video/mp4">
                </video>
            </div>
            <div class="yeshu">{{VideoChildListKey+1}}/{{VideoChildList.length}}</div>
            <div class="nextPage sizePage" @click="onnextPage()"><i class="el-icon-arrow-right"></i></div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ["VideoChildList"],
    data() {
        return {
            dialogVisible: false,
            video_url_link: "",
            VideoChildListKey: "", //当前播放视频的k
        };
    },

    mounted() {},

    methods: {
        onDialog(k) {
            this.dialogVisible = true;
            this.VideoChildListKey = k;
            this.video_url_link = this.VideoChildList[k].file.file_url;
            // console.log(this.VideoChildList[k])
            // console.log(this.VideoChildList)
            // console.log(this.VideoChildListKey)
            // this.video_url_link = video_url_link
            setTimeout(() => {
                this.$refs.videoLook.play();
            }, 1000);
        },
        onCancel() {
            (this.dialogVisible = false), (this.video_url_link = "");
        },
        //上一页
        onpreviousPage() {
            if (this.VideoChildListKey == 0) {
                this.$message({
                    message: "当前视频在本页里已经是第一条",
                    type: "warning",
                });
                return;
            }
            this.VideoChildListKey--;
            this.onVideoPlayorTing();
        },
        //下一页
        onnextPage() {
            if (this.VideoChildListKey == this.VideoChildList.length - 1) {
                this.$message({
                    message: "当前视频在本页里已经是最后一条",
                    type: "warning",
                });
                return;
            }
            this.VideoChildListKey++;
            this.onVideoPlayorTing();
        },
        //分页播放暂停视频
        onVideoPlayorTing() {
            this.video_url_link = "";
            // this.$refs.videoLook.pause()
            setTimeout(() => {
                this.video_url_link =
                    this.VideoChildList[this.VideoChildListKey].file.file_url;
            }, 100);
            setTimeout(() => {
                this.$refs.videoLook.play();
            }, 500);
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.lookVideo {
    padding: 30px 0;
    // min-height: 740px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &.el-dialog {
        overflow: initial;
        width: 100%;
    }
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 0 !important;
    }
    .el-dialog__headerbtn {
        font-size: 24px;
        right: -30px;
        top: 0;
    }
    .lookVideo-nei {
        position: relative;
        text-align: center;
        .yeshu {
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: -20px;
        }
        .sizePage {
            position: absolute;
            font-size: 32px;
            width: 70px;
            height: 70px;
            border-radius: 70px;
            line-height: 70px;
            text-align: center;
            background: rgba(255, 255, 255, 0.3);
            color: #fff;
            cursor: pointer;
        }
        .previousPage {
            left: -110px;
            top: calc(50% - 35px);
        }
        .nextPage {
            right: -110px;
            top: calc(50% - 35px);
        }
        .shipin {
            width: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            height: 720px;
            background: #000;
            video {
                max-height: 720px;
            }
        }
    }
}
</style>